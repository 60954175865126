@import '~styles/global';

.Container {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 55px;
  gap: 5px;

  @include medium {
    grid-template-columns: repeat(3, 180px);
    grid-template-rows: repeat(var(--rows), 32px);
    grid-auto-flow: column;
  }
}

.Link {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0 10px;
  background-color: $sbl-gray-11;
  @include hover {
    background-color: $sbl-gray-5;
  }
  @include font(18px, MEDIUM);
  @include medium {
    @include font-size(14px);
  }
}
