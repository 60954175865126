@import '@/styles/variables.scss';

.FixedHeader {
  top: 0;
  position: sticky;
  z-index: 10;
  box-shadow: $box-shadow-1;

  @media print {
    display: none;
  }
}
