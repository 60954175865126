@import '~styles/global';

$img-path: '../../../assets/images';

.AccountModal {
  .ModalSelect {
    margin-bottom: 15px;

    input {
      min-height: 50px !important;
      width: 100%;
    }

    select {
      min-height: 50px;
      width: 100%;
      font-size: 16px;

      @include medium {
        font-size: 14px;
      }
    }
  }
}

.AccountModal :global {
  .image-input {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    border: 2px solid $sbl-gray-5;
    overflow: hidden;
    margin: auto;
    margin-bottom: 15px;
    position: relative;
    background-color: black;

    input[type='file'] {
      display: none;
    }

    img {
      opacity: 0.7;
      max-width: 100%;
      max-height: 100%;
      margin: auto;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      position: absolute;
    }
  }

  .player-image {
    bottom: -50px;
    margin-left: 20px;
    border-radius: 50%;
    overflow: hidden;

    @include medium {
      margin-left: 10px;
    }
  }

  .player-cover_image {
    img {
      width: 100%;
      object-fit: cover;
    }
  }

  .upload-btn {
    background-image: url('#{$img-path}/icons/camera.png');
    background-color: transparent;
    border: none;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
    height: 30px;
    margin: auto;
    width: 30px;
    transition:
      height 0.3s,
      width 0.3s;
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .image-input:hover .upload-btn {
    height: 35px;
    width: 35px;
  }

  .image-input.player-cover_image {
    padding-bottom: 140px;
  }

  @include medium {
    .image-input.player-cover_image {
      padding-bottom: 150px;
    }
  }
}
