@import '~styles/global';

$img-path: '../../../../../assets/images';

.Nav {
  font-family: $font-family;
  background-color: $sbl-dark-blue-2;
}

.TopNavMobile {
  height: 50px;
  padding-top: 3px;
  background-color: $sbl-dark-blue-2;
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;

  .TopMobileLogo {
    display: flex;
    align-items: center;
  }

  .PoweredBy {
    margin-left: 2px;
    display: flex;
    flex-direction: column;
    color: $sbl-dark-blue-4;
    @include font(6px, 'SEMIBOLD');
    img {
      margin-top: 2px;
      width: 52px;
      margin-left: -2px;
    }
  }

  .SBLiveLogo {
    height: 25px;
    width: 154px;
  }

  .TopNavRight {
    margin-left: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 15px;

    div {
      margin-left: 20px;
    }

    @include medium {
      padding-right: 5px;
    }
  }
}

.TopNav {
  height: 30px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  box-sizing: border-box;
  background-color: #000000;
  padding-left: 15px;

  .LinkBlock {
    display: flex;
    flex-direction: row;

    div,
    .TextLink {
      display: flex;
      flex-direction: row;
      align-items: center;
      align-items: center;
      margin-right: 30px;
      text-transform: uppercase;
      font-size: 10px;
      font-weight: $font-weight-regular;
      color: #ffffff;
    }

    a:not(.ExcludedBlock) {
      box-sizing: border-box;
      height: 20px;
      width: 32px;
      display: inline-block;
      background-color: #333333;
      border-radius: 4px;
      margin-right: 6px;

      &:hover {
        background-color: #4b4b4b;
      }
    }

    a.ExcludedBlock {
      background-color: transparent;
      background-position: center;
      background-repeat: no-repeat;
      height: 20px;
      margin-right: 10px;

      &.TopIconSI {
        @include medium {
          background-size: 65px;
          background-position: left;
        }

        @include large {
          background-size: 105px;
          background-position: center;
        }
      }
    }
  }

  .LinkBlockRight {
    margin-left: auto;
    margin-right: 0;
    display: flex;
    align-items: center;
    padding-right: 10px;
    gap: 30px;
    div {
      margin-right: 7px;
    }
    img {
      height: 21px;
    }
  }

  .TopIcon {
    background-size: 10px;
    background-repeat: no-repeat;
    background-position: center;
  }

  .TopIconApple {
    background-image: url('#{$img-path}/nav/Apple.png');
  }

  .TopIconAndroid {
    background-image: url('#{$img-path}/nav/Android.png');
    background-size: 12px;
  }

  .TopIconSpotify {
    background-image: url('#{$img-path}/nav/Spotify.png');
  }

  .TopIconApplePodcasts {
    background-image: url('#{$img-path}/nav/ApplePodcasts.png');
  }

  .TopIconFacebook {
    background-image: url('#{$img-path}/nav/Facebook.png');
  }

  .TopIconTwitter {
    background-image: url('#{$img-path}/nav/Twitter.png');
  }

  .TopIconInstagram {
    background-image: url('#{$img-path}/nav/Instagram.png');
  }

  .TopIconTikTok {
    background-image: url('#{$img-path}/nav/TikTok.png');
  }

  .TopIconLinkedIn {
    background-image: url('#{$img-path}/nav/LinkedIn.png');
  }

  .TopIconYouTube {
    background-image: url('#{$img-path}/nav/YouTube.png');
  }

  .TopIconAAA {
    background-image: url('#{$img-path}/nav/AAA.png');
  }

  .TopIconCIF {
    background-image: url('#{$img-path}/nav/CIF.png');
  }

  .TopIconMHSAA {
    background-image: url('#{$img-path}/nav/MHSAA.png');
  }

  .TopIconMIAA {
    background-image: url('#{$img-path}/nav/MIAA.png');
  }

  .TopIconVPA {
    background-image: url('#{$img-path}/nav/VPA.png');
  }

  .TopIconTAG {
    background-image: url('#{$img-path}/nav/the-arena-group.png');
    background-size: 47px;
    width: 45px;
  }
}

.NavButton {
  box-sizing: border-box;
  background-color: $sbl-dark-blue-3;
  border: 1px solid $sbl-dark-blue-4;
  border-radius: 4px;
  font-size: 16px;
  height: 38px;
  display: flex;
  align-items: center;
  flex-direction: row;
  color: white;
  padding: 0 5px;
  font-weight: $font-weight-bold;
  text-transform: uppercase;

  @include medium {
    height: 28px;
    font-size: 14px;
  }

  &:hover {
    background-color: $sbl-dark-blue-5;
    cursor: pointer;
  }
}

.BlueNavButton {
  background-color: $sbl-blue-8;
  border: none;

  &:hover {
    background-color: $sbl-blue-2;
  }
}

.NavButtonRight {
  width: 45px;
  justify-content: center;
  margin-left: 10px;
}

.StatesButton {
  margin-right: 10px;
  padding-right: 25px;
  background-position: calc(100% - 8px) calc(50% + 1px);
  background-image: url('#{$img-path}/icons/white-select.png');
  background-size: 9px;
  background-repeat: no-repeat;

  @include medium {
    margin-right: 15px;
  }

  .State {
    border-color: $sbl-dark-blue-4;
    height: 20px;
    width: 25px;

    @include medium {
      height: 16px;
      width: 23px;
    }
  }
}

.State {
  height: 24px;
  width: 34px;
  margin-right: 10px;
  border: 1px solid $sbl-gray-5;
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: 0;

  @include medium {
    height: 14px;
    width: 20px;
    margin-right: 5px;
    border-radius: 1px;
  }
}

.SignInButton {
  width: 90px;
  justify-content: center;
  margin-left: 10px;

  @include small {
    background-color: $sbl-dark-blue-2;
    color: white;
  }
}

.BottomNav {
  height: 45px;
  width: 100%;
  background-color: $sbl-dark-blue-2;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  box-sizing: border-box;
  padding-bottom: 10px;

  @include medium {
    padding-bottom: initial;
    padding-left: 10px;
    padding-right: 10px;
  }

  .NavSearch {
    width: 250px;
    margin-left: auto;
    align-self: center;
    display: none;

    & > div {
      width: 250px;
    }

    input {
      height: 28px;
      box-sizing: border-box;
      width: 250px !important;
      background-position: 10px 8px;
      background-color: $sbl-dark-blue-3;
      border: none;
      color: white;
      background-image: url('#{$img-path}/nav/search-white.png');
      font-size: 14px;

      &:hover {
        background-color: $sbl-dark-blue-5;
        border: 1px solid $sbl-dark-blue-4;
        background-position: 9px 7px;
        cursor: text;
      }
    }

    @include medium {
      display: block;
    }

    input {
      width: 250px;
    }
  }

  .BottomNavRight {
    margin-left: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .SportOptions {
    display: flex;
    overflow: hidden;
    height: 45px;
    flex-wrap: wrap;

    a {
      color: white;
      margin-right: 15px;
      height: 45px;
      line-height: 45px;
      font-size: 16px;
      text-transform: uppercase;
      font-weight: $font-weight-bold;
      text-decoration: none;
      max-width: 120px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      @include medium {
        max-width: initial;
        font-size: 14px;
      }

      &:hover {
        color: $sbl-light-blue-1;
      }
    }

    .ActiveSport {
      color: $sbl-light-blue-1;
    }
  }
}

.SBLiveLogo {
  display: inline-block;
  height: 25px;
  width: 166.5px;
  margin-right: 10px;
  padding-right: 12.5px;
  padding-left: 5px;
  @include medium {
    border-right: 1px solid $sbl-dark-blue-4;
  }
}

.ImageSelect {
  display: flex;
  border-radius: 5px;
  align-self: center;
  font-weight: $font-weight-heavy;
  position: relative;
  align-items: center;

  &.SportsImageSelect {
    @include medium {
      height: 100%;
    }

    &:hover {
      .MenuBuffer {
        height: 25px;
        bottom: -25px;
        right: 0;
      }
    }

    .ImageSelectMenu {
      top: 50px;

      &.ImageSelectMenuRight {
        @include medium {
          left: -200px;
        }

        @include large {
          left: inherit;
        }
      }
    }
  }

  .ImageSelectMenu {
    padding: 15px;
    display: none;
    position: absolute;
    top: 40px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: 1px solid $sbl-gray-5;
    background-color: white;
    overflow: hidden;
    z-index: 9999;
    border-radius: 5px;
    left: 0;

    @include medium {
      left: -135px;
    }

    @include large {
      left: 0;
    }

    * {
      line-height: 1;
    }

    & > div {
      @include medium {
        flex-wrap: wrap;
        max-width: 740px;
        width: 740px;
      }

      @include large {
        flex-wrap: nowrap;
        max-width: initial;
        width: initial;
      }
    }

    div {
      align-self: initial;
    }

    &.ImageSelectMenuRight {
      left: inherit;
      right: 0;

      @include medium {
        left: -165px;
        right: inherit;
      }

      @include large {
        left: inherit;
        right: 0;
      }

      & > div {
        @include medium {
          flex-wrap: nowrap;
          max-width: initial;
          width: initial;
        }
      }
    }
    &.ImageSelectMenuCenter {
      left: inherit;
      right: 0;

      @include medium {
        left: -165px;
        right: inherit;
      }

      @include large {
        left: inherit;
        right: -185px;
      }

      & > div {
        @include medium {
          flex-wrap: nowrap;
          max-width: initial;
          width: initial;
        }
      }
    }
  }

  &:hover {
    .MenuBuffer {
      height: 15px;
      width: 150%;
      background-color: transparent;
      position: absolute;
      bottom: -15px;
      z-index: 11;
    }

    svg circle {
      fill: $sbl-light-blue-1;
    }

    @include medium {
      .ImageSelectMenu {
        display: flex;
      }
    }
  }
}

.FavoritesMenu {
  @include medium {
    max-height: 500px;
    overflow-y: scroll;
  }

  .FavoritesRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-transform: initial;
    color: black;
    text-decoration: none;
    white-space: nowrap;
    background-color: $sbl-gray-11;
    padding: 10px;
    font-size: 18px;
    font-weight: $font-weight-regular;
    border-radius: 4px;
    box-sizing: border-box;
    min-width: 180px;
    min-height: 55px;
    margin-bottom: 5px;

    @include medium {
      font-size: 14px;
      min-height: 32px;
    }

    &:hover {
      background-color: $sbl-gray-5;
    }

    img {
      background-color: white;
      height: 30px;
      width: 30px;
      margin-right: 10px;
      margin-bottom: 0;

      @include medium {
        margin-right: 5px;
        height: 20px;
        width: 20px;
      }
    }

    div div:last-of-type {
      font-size: 16px;
      font-weight: $font-weight-light;
      margin-top: 3px;

      @include medium {
        margin-top: 1px;
        font-size: 12px;
      }
    }

    & > div {
      display: flex;
      flex-direction: column;
      text-transform: initial;
    }
  }

  .ViewMoreBorder {
    @include medium {
      margin-bottom: 12px;
      margin-top: 12px;
      border-top: 1px dotted $sbl-gray-12;
    }
  }

  .ViewMoreTeams {
    text-transform: capitalize;
    font-size: 14px;
    font-weight: $font-weight-bold;
    color: $sbl-blue-8;

    &:hover {
      color: $sbl-blue-2;
    }
  }
}

.DefaultStateButton {
  color: white;
  border: 1px solid white;
  background-color: #4c96d8;
  line-height: 14px;
}

.DefaultStateBanner {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  box-sizing: border-box;
  height: 85px;
  background-color: $sbl-blue-8;
  padding-top: 15px;
  display: flex;
  justify-content: center;

  svg {
    position: absolute;
    height: 14px;
    right: 12px;
    padding: 5px;
    top: 11px;
  }
}

.NavSubMenu {
  display: flex;
  flex-direction: column;

  @include medium {
    flex-direction: row;
  }

  .StateColumn {
    display: flex;
    flex-direction: column;

    a {
      display: flex;
      height: 55px;
      width: 100%;
      flex-direction: row;
      align-items: center;
      background-color: $sbl-gray-11;
      color: black;
      text-decoration: none;
      font-size: 18px;
      font-weight: $font-weight-regular;
      margin-bottom: 5px;
      margin-right: 5px;
      border-radius: 2px;
      box-sizing: border-box;
      padding: 0 10px;

      svg {
        margin-left: auto;
        height: 16px;
        width: 16px;
      }

      @include medium {
        width: 180px;
        font-size: 14px;
        height: 32px;
      }

      &:hover {
        background-color: $sbl-gray-5;
      }
    }

    .DefaultState {
      font-weight: $font-weight-bold;
      font-size: 14px;
      margin-top: 5px;
      color: $sbl-blue-8;

      &:hover {
        color: $sbl-blue-2;
        cursor: pointer;
      }
    }
  }
}

.NavSearchComponent :global {
  a,
  a div {
    text-decoration: none;
    text-decoration-color: transparent;
  }

  .TeamBlockImageWrapper {
    height: 24px;
    width: 24px;
    display: block;
    margin-right: 10px;
  }

  .SearchResult {
    background-color: $sbl-gray-11;
    padding: 5px 10px;
    margin-bottom: 5px;
    border-top: none;

    &:hover {
      background-color: $sbl-gray-5;
    }
  }

  .SearchResultTeamBlock {
    * {
      line-height: 1;
    }

    img {
      height: 24px !important;
      width: 24px !important;
      margin-right: -10px;
    }
  }

  .ViewAllResults {
    margin-top: 10px;
    padding-top: 0;
    color: $sbl-blue-8;

    &:hover {
      background-color: white;
      color: $sbl-blue-2;
    }
  }
}

html :global {
  //forcing admin headers to top as there are no google ads on their pages
  .admin-yes .sbl-header-nav {
    top: 0;
  }

  .sticky-header {
    position: sticky;
    top: 0;
    z-index: 10;
  }
}

body :global {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  #global-nav-wrapper {
    position: relative;
  }

  .sbl-header-nav {
    position: fixed;
    width: 100%;
    z-index: 10;
  }

  .pg-container {
    padding-top: 125px;

    @include medium {
      padding-top: 115px;
    }
  }

  .section-teams.pg-index.admin-yes {
    padding-top: 65px;

    @include medium {
      padding-top: 50px;
    }
  }

  i.white-icon {
    background-color: $sbl-dark-blue-9;
    border-radius: 100%;
  }

  i.navy-icon {
    background-color: white;
    border-radius: 100%;
    border: 1px solid $sbl-gray-5;
  }

  .select {
    appearance: none;
  }

  .pg-wrapper > header + .google-auto-placed {
    padding-top: 125px;
    margin-bottom: -100px;
  }
}
