@import '~styles/global';

.TeamBlock {
  padding: 10px 0;
  flex: 1;

  img {
    height: 40px;
    width: 40px;
    box-sizing: border-box;
  }
}

.TeamBlockLink {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: $font-weight-regular;
  color: $sbl-blue-8;
  width: fit-content;

  &:hover {
    color: $sbl-blue-2;
  }
}

.SmallTeamImage {
  img {
    @include medium {
      height: 30px;
      width: 30px;
      margin-bottom: 2px;
    }
  }
}

.TeamBlockImage {
  display: flex;
  flex-direction: column;
  margin-right: 5px;
  align-items: center;
  color: black;
  font-size: 10px;
  font-weight: $font-weight-heavy;
}

.TeamBlockText {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.TeamBlock {
  &.v2 {
    .TeamBlockLink {
      width: 100%;
    }

    img {
      height: 20px;
      width: 20px;
    }

    .TeamBlockText {
      color: $sbl-blue-4;
      font-size: 15px;
      font-weight: $font-weight-semibold;
      width: 100%;

      @include medium {
        font-size: 14px;
      }
    }

    a {
      &:hover {
        color: $sbl-blue-5;
      }
    }
  }
}
