@import '@/styles/variables.scss';

.NavBar {
  background-color: #fff;
  border-bottom: $sbl-gray-5;
  padding: 6px 0;
}

.Content {
  display: flex;

  //@include not-large {
  //  margin-left: 14px !important;
  //}

  margin-left: 6px;
  @include medium {
    margin-left: 14px;
  }
}

.Left {
  padding-right: 10px;
  border-right: 1px solid $sbl-gray-blue-1;
  margin-right: 10px;
}

.Links {
  display: flex;
  gap: 6px;
  overflow: auto;
  padding-right: 6px;
  @include medium {
    padding-right: 14px;
  }
}
