@import '~styles/global';

$img-path: '../../../assets/images';

.Search {
  position: relative;
  width: 400px;

  input {
    height: 55px;
    width: 100%;
    background-color: $sbl-gray-11;
    background-image: url('#{$img-path}/icons/search-gray.png');
    background-position: 10px 15px;
    background-repeat: no-repeat;
    background-size: 20px;
    border: 2px solid $sbl-gray-9;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
      'Segoe UI Symbol', sans-serif;
    cursor: pointer;
    font-weight: $font-weight-light;
    font-size: 16px;
    padding: 5px 0 5px 40px;
    text-transform: none;
    min-width: 100px;
    border-radius: 5px;
    white-space: nowrap;
    appearance: none;

    @include medium {
      height: 40px;
      background-position: 10px 11px;
      background-size: 14px;
      padding: 5px 0 5px 35px;
    }

    &:focus,
    &:hover {
      outline: none;
      border: 2px solid $sbl-blue-8;
    }
  }

  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
}

.ResultContainer {
  padding: 0 10px 0 10px;
  overflow-y: scroll;
  max-height: 60vh;

  @include medium {
    max-height: 500px;
  }
}

.ResultHeader {
  font-size: 16px;
  font-weight: $font-weight-heavy;
  padding: 10px 0 10px 15px;
  border-bottom: 2px solid $sbl-gray-5;

  @include medium {
    font-size: 14px;
    font-weight: $font-weight-bold;
  }
}

.SearchResults {
  position: absolute;
  left: 0;
  right: 0;
  top: 55px;
  width: 100%;
  background-color: white;
  border: 2px solid $sbl-gray-5;
  border-radius: 3px;
  padding-bottom: 15px;
  box-sizing: border-box;
  z-index: 99999;
  margin-top: 10px;

  @include medium {
    margin-top: 0;
    border: 1px solid $sbl-gray-5;
  }
}

.SearchResult {
  min-height: 45px;
  padding: 7px 0;
  padding-left: 5px;
  box-sizing: border-box;
  border-top: 1px dotted $sbl-gray-5;
  display: flex;
  align-items: center;
  border-radius: 5px;

  @include medium {
    padding: 5px 0;
  }

  .SearchResultTeamBlock {
    padding: 0;

    img {
      height: 30px;
      width: 30px;
    }
  }

  &:first-of-type {
    border-top: none;
  }

  &:hover {
    background-color: $sbl-gray-11;
    cursor: pointer;
  }

  + .ViewAllResults {
    margin-top: 0;
  }
}

.ViewAllResults {
  font-size: 14px;
  color: black;
}

.SearchResultText {
  display: flex;
  flex-direction: column;

  div:first-of-type {
    color: black;
    font-size: 18px;
    font-weight: $font-weight-regular;
    margin-bottom: 2px;

    @include medium {
      font-size: 16px;
    }
  }

  div:last-of-type {
    color: black;
    font-size: 16px;
    font-weight: $font-weight-light;

    @include medium {
      font-size: 14px;
    }
  }
}

.ClearText {
  position: absolute;
  right: 15px;
  top: 13px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
